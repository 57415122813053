import * as React from "react";
import Box from "@mui/material/Box";
import {
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import {
  SiteMainItem,
} from "../../interfaces/contentLanguage";
import languageContentService from "../../services/languageService";
import TableItem from "./TableItem";

export default function ContentLanguagePage() {
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("golf");


  const [listMainSite, setListMainSite] =
    React.useState<SiteMainItem[] | null>(null);

  const getListData = async () => {
    setLoading(true);
    try {
      const response = await languageContentService.getListMainSite();
      if (response?.status === 200) {
        setListMainSite(response.data);
      }
    } catch (e: any) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    document.title = "Product Categories";
  }, []);

  React.useEffect(() => {
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>

    {/* <Button variant="contained" onClick={() => setOpen(true)} sx={{mb: 2}}>Add Root</Button> */}
    <Stack>
        {/* <TableHeader /> */}
          {listMainSite && listMainSite.map((row: SiteMainItem, index: number) => (
            <TableItem key={index} item={row} callback={getListData} />
          ))}
        </Stack>
    </Box>
  );
}

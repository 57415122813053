import { createBrowserRouter } from "react-router-dom";
import MainSiteLayout from "../layouts/MainSiteLayout";
import TKGTreeView from "../layouts/menu";

import HomePage from "../pages/home/HomePage";
import UserProfile from "../pages/profile/UserProfile";
import ContentLanguagePage from "../pages/content-language";

// @ts-ignore
const router = createBrowserRouter(
    [
        {
            path: "/",
            element:<MainSiteLayout memuList={<TKGTreeView />} />,
            children: [
                {
                    path: "/",
                    element: <ContentLanguagePage />
                },
                {
                    path: "/profile/:id",
                    element: <UserProfile />
                },
                {
                    path: "/content-language",
                    element: <ContentLanguagePage />
                }
            ],
        },


    ]
)

export default router;

import "./styles/main.scss";
import { ThemeProvider, createTheme } from "@mui/material";
import { RouterProvider } from 'react-router-dom';
import router from './routes';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import { useState } from "react";
import { MyGlobalContext } from "./context/MyGlobalContext";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY as string);

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: "#174D75",
      },
    },
    components: {
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {
              width: ".8em",
              height: ".8em",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            fontSize: 14,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderColor: "#d5d5d5",
            },
            "&.MuiInputBase-adornedStart input": {
              borderLeft: "1px solid #d5d5d5",
              borderTopLeftRadius: "0 !important",
              borderBottomLeftRadius: "0 !important",
            },
            "&.MuiInputBase-adornedStart.MuiAutocomplete-inputRoot input": {
              borderLeft: "none",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: "primary",
            },
            "&.MuiInputLabel-root": {
              fontWeight: 600,
              color: "black !important"
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },
  });
  const [mode, setMode] = useState(localStorage.getItem("mode") ?? "light");
  const [companyLogo, companyName] = useState("");
  const [setCompanyLogo, setCompanyName] = useState("");

  return (
//     <MyGlobalContext.Provider
//       value={{
//         mode,
//         setMode,
//         companyLogo,
// companyName,
// setCompanyLogo,
// setCompanyName,
//       }}
//     >
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
      // </MyGlobalContext.Provider>
  );
}

export default App;

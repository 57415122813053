import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { makeid } from "../../utils";
import {
  ContentLanguageForm,
  ContentLanguagePayload,
  DetailContentLanguageResponse,
  LanguageGroupItem,
  SiteMainItem,
} from "../../interfaces/contentLanguage";
import validationMessage from "../../constants/validationMessage";
import languageContentService from "../../services/languageService";
import Swal from "sweetalert2";
const validationSchema = yup.object().shape({
  section: yup.string().required(validationMessage.required),
  contents: yup.array(
    yup.object().shape({
      lang: yup.string().required(validationMessage.required),
      content: yup
        .string()
        .nullable()
        .transform((v) => (v === undefined ? null : v)),
    })
  ),
  tag: yup
    .string()
    .nullable()
    .transform((val) => (val === undefined ? null : val)),
  url: yup
    .string()
    .nullable()
    .transform((val) => (val === undefined ? null : val)),
  icon: yup
    .string()
    .nullable()
    .transform((val) => (val === undefined ? null : val)),
});

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  callback: () => void;
  dataDetail?: LanguageGroupItem;
  isEdit?: boolean;
  isAddChild?: boolean;
  siteData: SiteMainItem;
  level: number;
}
const FormContentLanguage = ({
  open,
  setOpen,
  callback,
  dataDetail,
  isEdit,
  isAddChild,
  siteData,
  level,
}: Props) => {
  const [keyReset, setKeyReset] = useState("");
  const [contentDataDetail, setContentDataDetail] =
    useState<DetailContentLanguageResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [isEditBtn, setIsEditBtn] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setIsEditBtn(true);
    } else {
      setIsEditBtn(false);
    }
  }, [isEdit]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContentLanguageForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const { fields } = useFieldArray({
    control,
    name: "contents",
  });

  const getDataDetail = async (id: number) => {
    const response = await languageContentService.getContentById(id);
    if (response?.status === 200) {
      const data: DetailContentLanguageResponse = response.data;
      setContentDataDetail(data);
      if (!!isEdit && dataDetail) {
        console.log(dataDetail);

        const initData = {
          tag: "Sidebar",
          section: data.en.section,
          url: dataDetail?.tags?.url ?? "",
          icon: dataDetail?.tags?.icon ?? "",
          contents: [
            {
              lang: "en",
              content: data.en.content,
            },
            {
              lang: "fr",
              content: data.fr.content,
            },
            {
              lang: "cn",
              content: data.cn.content,
            },
          ],
          childType: data.en.childType,
        };
        reset(initData);
        setKeyReset(makeid(5));
      } else {
        const initData = {
          tag: "Sidebar",
          section: `SIDEBAR_${makeid(8)}`,
          url: "",
          contents: [
            {
              lang: "en",
              content: "",
            },
            {
              lang: "fr",
              content: "",
            },
            {
              lang: "cn",
              content: "",
            },
          ],
        };
        reset(initData);
        setKeyReset(makeid(5));
      }
    }
  };

  useEffect(() => {
    console.log(dataDetail, level);
    
    if (dataDetail) {
      getDataDetail(dataDetail?.id);
    } else {
      const initData = {
        tag: "Sidebar",
        section: `SIDEBAR_${makeid(8)}`,
        url: "",
        contents: [
          {
            lang: "en",
            content: "",
          },
          {
            lang: "fr",
            content: "",
          },
          {
            lang: "cn",
            content: "",
          },
        ],
      };
      reset(initData);
    }
  }, [dataDetail]);

  const submit: SubmitHandler<ContentLanguageForm> = async (data) => {
    if (!!isEdit && dataDetail) {
      const payload: ContentLanguagePayload = {
        mainSiteId: siteData.id,
        section: data?.section ?? "",
        contents: data?.contents
          ? data?.contents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
        tags: JSON.stringify({
          type: data.tag ?? "",
          parentId: dataDetail ? dataDetail.parentId : null,
          level: level,
          url: data.url ?? "",
          icon: data.icon ?? "",
        }),
      };
      const response = await languageContentService.updateContentLanguage(
        dataDetail?.key,
        payload
      );

      if (response?.status === 200) {
        Swal.fire({
          title: `Update Language Successfully!`,
          showConfirmButton: true,
          icon: "success",
          position: "center",
        });
        callback();
        setOpen(false);
      }
    } else {
      const payload: ContentLanguagePayload = {
        mainSiteId: siteData.id,
        section: data?.section ?? "",
        contents: data?.contents
          ? data?.contents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
        tags: JSON.stringify({
          type: data.tag ?? "",
          parentId: dataDetail ? dataDetail.id : null,
          level: dataDetail ? dataDetail?.tags.level + 1 : level,
          url: data.url ?? "",
          icon: data.icon ?? "",
        }),
      };
      const response = await languageContentService.storeContentLanguage(
        payload
      );

      if (response?.status === 200) {
        Swal.fire({
          title: `Store Language Successfully!`,
          showConfirmButton: true,
          icon: "success",
          position: "center",
        });
        callback();
        setOpen(false);
      }
    }
  };

  const renderTitleLang = (lang: string) => {
    switch (lang) {
      case "en":
        return "English";
      case "fr":
        return "French";
      case "cn":
        return "Chinese";
      default:
        return "";
    }
  };

  const listIcons = [
    {label: "Master SEO", value: "/sidebar-icons/master-seo.svg"},
    {label: "Master Setup", value: "/sidebar-icons/master-setup.svg"},
    {label: "Homepage", value: "/sidebar-icons/homepage.svg"},
    {label: "Navigation Menu", value: "/sidebar-icons/navigation-menu.svg"},
    {label: "Tour Types", value: "/sidebar-icons/navigationMenu_tour-types.svg"},
    {label: "Destinations", value: "/sidebar-icons/navigationMenu_destinations.svg"},
    {label: "Rail Packages", value: "/sidebar-icons/navigationMenu_rail-packages.svg"},
    {label: "Why Choose Us", value: "/sidebar-icons/navigationMenu_why-choose-us.svg"},
    {label: "Find Local Agent", value: "/sidebar-icons/navigationMenu_find-local-agent.svg"},
    {label: "Inspiration", value: "/sidebar-icons/navigationMenu_inspiration.svg"},
    {label: "Contact Us", value: "/sidebar-icons/navigationMenu_contact-us.svg"},
    {label: "Hero Section", value: "/sidebar-icons/hero-section.svg"},
    {label: "Feature Destination", value: "/sidebar-icons/feature-destination.svg"},
    {label: "Feature Service", value: "/sidebar-icons/feature-service.svg"},
    {label: "Best Selling Products", value: "/sidebar-icons/best-selling-products.svg"},
    {label: "Why Choose Us?", value: "/sidebar-icons/why-choose-us.svg"},
    {label: "Customer Review", value: "/sidebar-icons/customer-review.svg"},
    {label: "Add Review", value: "/sidebar-icons/customerReview_add-review.svg"},
    {label: "All Reviews", value: "/sidebar-icons/customerReview_all-reviews.svg"},
    {label: "Know More", value: "/sidebar-icons/know-more.svg"},
    {label: "Finder / Form", value: "/sidebar-icons/knowMore_finder.svg"},
    {label: "Flyer / Brochure", value: "/sidebar-icons/knowMore_flyer.svg"},
    {label: "Inspiration By Us", value: "/sidebar-icons/knowMore_inspiration-by-us.svg"},
    {label: "Other", value: "/sidebar-icons/other.svg"},

    {label: "Footer", value: "/sidebar-icons/footer.svg"},
    {label: "Products Display Page", value: "/sidebar-icons/products-display.svg"},
    {label: "Itinerary Page", value: "/sidebar-icons/itinerary.svg"},

  ]
  return (
    <Dialog maxWidth="md" fullWidth open={open}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: 2,
          bgcolor: "#103551",
          color: "#fff",
          fontWeight: "bold",
        }}
      >
        {dataDetail
          ? `Add Child of ${contentDataDetail?.en.content}`
          : `Add Content`}

        <IconButton aria-label="close" onClick={() => setOpen(false)}>
          <Close sx={{ color: "#fff" }} />
        </IconButton>
      </DialogTitle>
      <form id={`form-handle-task`} onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <Box>
            {loading ? (
              <Stack
                sx={{ width: "100%" }}
                spacing={2}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress />
              </Stack>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <TextField
                    className={isEditBtn ? "bg-readonly" : ""}
                    {...register(`url`)}
                    label={"Path"}
                    placeholder="Ex: /homepage/master-seo"
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ readOnly: isEditBtn }}
                  />
                </Grid>
                <Grid item md={4}>
                      <Controller
                          control={control}
                          name={`icon`}
                          render={({ field: { onChange, onBlur, value, ref } }) => {
                            return (
                                <Autocomplete
                                className={isEditBtn ? "bg-readonly" : ""}
                                    readOnly={isEditBtn}
                                    disablePortal
                                    sx={{ mb: 0 }}
                                    disableClearable
                                    id={`icon`}
                                    options={listIcons}
                                    value={value ? listIcons.find(
                                        (item) => item.value === value
                                    ) : {label: "", value: ""}}
                                    onChange={(e, value) => {
                                      onChange(value.value);
                                    }}
                                    size="small"
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                              "& > img": { flexShrink: 0, mr: 3 },
                                            }}
                                            {...props}
                                        >
                                          <img
                                              alt="svg icon"
                                              src={`${process.env.REACT_APP_URL_DOMAIN}/${option.value}`}
                                              width={20}
                                          />

                                          {option.label}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              borderRadius: 1,
                                            }}
                                        >
                                          {value && (
                                              <Box
                                                  sx={{
                                                    padding: "0 10px",
                                                    border: "1px solid #d0d0d0",
                                                    height: "45px",
                                                    position: "relative",
                                                    top: -5,
                                                    borderRadius: "10px 0 0 10px",
                                                    borderRight: "none",
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                              >
                                                <img
                                                    alt="svg icon"
                                                    src={`${process.env.REACT_APP_URL_DOMAIN}/${value}`}
                                                    width={20}
                                                />
                                              </Box>
                                          )}
                                          <TextField
                                              sx={{
                                                bgcolor: "#fff",
                                                "& .MuiInputBase-root": {
                                                  borderTopLeftRadius: "0 !important",
                                                  borderBottomLeftRadius: "0 !important",
                                                },
                                              }}
                                              {...params}
                                              label={"Icon"}
                                              defaultValue={""}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              
                                          />
                                        </Box>
                                    )}
                                />
                            );
                          }}
                      />
                    </Grid>
                {fields.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <TextField
                      className={isEditBtn ? "bg-readonly" : ""}
                      {...register(`contents.${index}.content`)}
                      label={renderTitleLang(item.lang)}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ readOnly: isEditBtn }}
                      error={errors.contents?.[index]?.content !== undefined}
                      helperText={
                        errors.contents?.[index]?.content !== undefined
                          ? validationMessage.required
                          : ""
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {isEditBtn ? (
            <Button variant={"contained"} onClick={() => setIsEditBtn(false)}>
              Edit
            </Button>
          ) : (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Button
                variant={"contained"}
                onClick={() => setIsEditBtn(true)}
                sx={{
                  bgcolor: "#808080",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant={"contained"}>
                Submit
              </Button>
            </Stack>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormContentLanguage;

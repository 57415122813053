import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { LanguageGroupItem, SiteMainItem } from "../../interfaces/contentLanguage";
import { ArrowDropDown } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import FormContentLanguage from "./FormContentLanguage";
import Swal from "sweetalert2";
import languageContentService from "../../services/languageService";
interface Props {
  item: LanguageGroupItem;
  callback: () => void;
  backgroundColor: string;
  keyLevel: number;
  siteData: SiteMainItem
}
const TableItemChild = ({ item, callback, backgroundColor, keyLevel, siteData }: Props) => {
  const options = ["Edit", "Add Child", "Delete"]
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [openBtn, setOpenBtn] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isAddChild, setIsAddChild] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openConfig, setOpenConfig] = React.useState(false);
  const handleDelete = (keySection: string) => {
    Swal.fire({
      icon: "question",
      title: "Warning!",
      text: "Delete this? You can't revert this.",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      confirmButtonColor: "#d32f2f",
    }).then(async (res) => {
      if (res?.isConfirmed) {
        const response = await languageContentService.deleteContentLanguage(keySection);
        if (response?.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Delete successfully",
          });
          callback();
        }
      }
    });
  };
  const handleToggle = () => {
    setOpenBtn((prevOpen) => !prevOpen);
  };

  const storeChild = () => {
    setOpenEditModal(true);
    setIsEdit(false);
    setIsAddChild(true);
  };
  const editChild = () => {
    setOpenEditModal(true);
    setIsEdit(true);
    setIsAddChild(true);
  };
  const configField = () => {
    setOpenConfig(true);
  };
  const handleClick = (node: LanguageGroupItem) => {
    switch (options[selectedIndex]) {
      case "Add Side Menu":
      case "Add Child":
        return storeChild();
      case "Edit":
        return editChild();
        case "Config Fields":
        return configField();
      case "Delete":
        return handleDelete(node.key);
      default:
        return;
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    node: LanguageGroupItem
  ) => {
    setSelectedIndex(index);
    setOpenBtn(false);
    switch (options[index]) {
      case "Add Side Menu":
      case "Add Child":
        return storeChild();
      case "Edit":
        return editChild();
        case "Config Fields":
        return configField();
      case "Delete":
        return handleDelete(node.key);
      default:
        return;
    }
  };
  return (
    <Box sx={{bgcolor: '#fff'}}>
      <Box>
        <TableCell width={"5%"} sx={{py: "5px"}}>
        {item.children && item.children.length > 0 ? <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton> : null}
        </TableCell>
        <TableCell align="left" width={"9%"} sx={{py: "5px"}}>{`Level ${keyLevel}`}</TableCell>
        <TableCell align="left" width={"27%"} sx={{py: "5px"}}>{item.enContent}</TableCell>
        <TableCell align="left" width={"27%"} sx={{py: "5px"}}>{item.frContent}</TableCell>
        <TableCell align="left" width={"27%"} sx={{py: "5px"}}>{item.cnContent}</TableCell>
        <TableCell align="right" width={"5%"} sx={{py: "5px"}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
              color={options[selectedIndex] === "Delete" ? "error" : "inherit"}
              sx={{ boxShadow: "unset", borderRadius: "20px" }}
            >
              <Button
                onClick={() => handleClick(item)}
                sx={{
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                }}
              >
                {options[selectedIndex]}
              </Button>
              <Button
                size="small"
                aria-controls={openBtn ? "split-button-menu" : undefined}
                aria-expanded={openBtn ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={() => {
                  handleToggle();
                }}
                sx={{
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
            <Popper
              open={openBtn}
              anchorEl={anchorRef.current}
              role={undefined}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpenBtn(false)}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        // selected={
                        //   `${item.id}-${index}` ===
                        //   `${selectNodeId}-${selectedIndex}`
                        // }
                        onClick={(event) => {
                          handleMenuItemClick(event, index, item);
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </Box>
        </TableCell>
      </Box>
      {item.children && item.children.length > 0 && (
               <Collapse in={open} timeout="auto" unmountOnExit>

              <Box sx={{bgcolor: "#fff"}}>
                  <Stack sx={{pl: 3}}>
                    {item.children?.map((child) => (
                      <TableItemChild item={child} callback={callback} backgroundColor={backgroundColor} keyLevel={keyLevel + 1} siteData={siteData} />
                    ))}
                  </Stack>
              </Box>
              </Collapse>

      )}

      {openEditModal && (
              <FormContentLanguage
                open={openEditModal}
                setOpen={setOpenEditModal}
                callback={callback}
                dataDetail={item}
                isEdit={isEdit}
                isAddChild={isAddChild}
                siteData={siteData}
                level={keyLevel}
              />
            )}
           
    </Box>
  );
};

export default TableItemChild;

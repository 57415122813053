const endPointAPI = {
    USER: {
        CURRENT: "/user/current",
        FIND_SIMPLE: "/user/find-simple"
    },
    LANGUAGE_CONTENT: {
        STORE: "/language-content/contents/store",
        UPDATE: "/language-content/contents/update",
        DELETE: "/language-content/contents/delete",
        GET_BY_ID: "/language-content/contents/find-detail-by-id",
        GET_LIST_LANG: "/language-content/contents/get-list-lang",
        GET_LIST_MAIN_SITE: "/language-content/contents/get-list-main-site",
    }


}

export default endPointAPI;
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Card,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { ContentLanguageResponse, LanguageGroupItem, LanguageItem, SiteMainItem } from "../../interfaces/contentLanguage";
import { ArrowDropDown } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import FormContentLanguage from "./FormContentLanguage";
import Swal from "sweetalert2";
import languageContentService from "../../services/languageService";
import TableItemChild from "./TableItemChild";
import { toNestedLangGroup } from "../../utils";
interface Props {
  item: SiteMainItem;
  callback: () => void;
}

const TableItem = ({ item, callback }: Props) => {
  const options = ["Add Sidebar"];
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [openBtn, setOpenBtn] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isAddChild, setIsAddChild] = React.useState(false);
  const [listData, setListData] = React.useState<LanguageGroupItem[] | null>(null);

  const handleToggle = () => {
    setOpenBtn((prevOpen) => !prevOpen);
  };

  const storeChild = () => {
    setOpenEditModal(true);
    setIsEdit(false);
    setIsAddChild(true);
  };
  const editChild = () => {
    setOpenEditModal(true);
    setIsEdit(true);
    setIsAddChild(true);
  };

  const handleClick = (node: SiteMainItem) => {
    switch (options[selectedIndex]) {
      case "Add Side Menu":
      case "Add Sidebar":
        return storeChild();
      case "Edit":
        return editChild();
      default:
        return;
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    node: SiteMainItem
  ) => {
    setSelectedIndex(index);
    setOpenBtn(false);
    switch (options[index]) {
      case "Add Side Menu":
      case "Add Sidebar":
        return storeChild();
      case "Edit":
        return editChild();
      default:
        return;
    }
  };

  const getDataList = async (id: number) => {
    const response = await languageContentService.getListContent(id);
    if(response?.status === 200) {
      const listData: ContentLanguageResponse = response.data;

      const formatData: LanguageGroupItem[] = listData.en.filter((item: LanguageItem) => JSON.parse(item.tags ?? "{}")?.type === "Sidebar").map((item, index) => {

        const tags = JSON.parse(item.tags ?? '{}')
        
        console.log(tags, tags?.parentId);
        
        return ({
        id: item.id,
        key: item.section,
        tags: tags ? tags : null, 
        parentId: tags?.parentId ? tags?.parentId : null,
        enContent: item.content,
        frContent: listData.fr.find(el => el.id === item.id && el.section === item.section)?.content ?? "",
        cnContent: listData.cn.find(el => el.id === item.id && el.section === item.section)?.content ?? "",
        mainSiteId: item.mainSiteId
      })
      } )
      

      console.log(formatData, toNestedLangGroup(formatData));
      
      setListData(toNestedLangGroup(formatData))
    }
  }

  useEffect(() => {
    if(item && item.id && open)
    getDataList(item.id);
  }, [open, item])
  
  return (
    <Box sx={{ mb: 2, }}>
      <Stack direction={"row"} alignItems={"center"} sx={{ p: 1, borderRadius: "4px", bgcolor: "#fff", boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)"}}>
        <Typography component={'span'} width={"5%"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Typography>
        <Typography component={'span'} width={"80%"} className="fw-600">
          {item.name}
        </Typography>
        {/* <Typography component={'span'} width={"15%"} className="fw-600">
          {item.version}
        </Typography> */}
  
        <Typography component={'span'} width={"15%"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
              color={options[selectedIndex] === "Delete" ? "error" : "inherit"}
              sx={{ boxShadow: "unset", borderRadius: "20px" }}
            >
              <Button
                onClick={() => handleClick(item)}
                sx={{
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                }}
              >
                {options[selectedIndex]}
              </Button>
              <Button
                size="small"
                aria-controls={openBtn ? "split-button-menu" : undefined}
                aria-expanded={openBtn ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={() => {
                  handleToggle();
                }}
                sx={{
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
            <Popper
              open={openBtn}
              anchorEl={anchorRef.current}
              role={undefined}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpenBtn(false)}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        // selected={
                        //   `${item.id}-${index}` ===
                        //   `${selectNodeId}-${selectedIndex}`
                        // }
                        onClick={(event) => {
                          handleMenuItemClick(event, index, item);
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </Box>
        </Typography>
      </Stack>
      {listData && listData.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box>
            {/* <Typography variant="h6" gutterBottom component="div">
                  Child
                </Typography> */}
            <Stack>
              {listData?.map((child, index) => (
                <Box
                > 
                  <TableItemChild item={child} callback={callback} backgroundColor={index % 2 === 0 ? "#e7f1f8" : "#fff"} keyLevel={2} siteData={item} />
                </Box>
              ))}
            </Stack>
          </Box>
        </Collapse>
      )}
       {openEditModal && (
       <FormContentLanguage
          open={openEditModal}
          setOpen={setOpenEditModal}
          callback={callback}
          isAddChild={isAddChild}
          siteData={item}
          level={1}
        />
      )}
           
    </Box>
  );
};

export default TableItem;
